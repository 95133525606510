import { useEffect, useState } from 'react'
import { OrbitControls } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import { Perf } from 'r3f-perf'
// import Model from './Model.jsx'
import { Suspense } from 'react'
import Placeholder from './Placeholder.jsx'
import Goal from './Goal.jsx'

// Eye tracking Parallax
import * as Parallax from 'parallax-effect';

export default function Experience()
{

    // Global camera
    const camera = useThree(state => state.camera)

    // const [cameraPos, updateCameraPos] = useState({
    //   x: -4,
    //   y: 3,
    //   z: 6
    // })

    const parallaxAmp = 1.5

    const initParallax = function() {
      Parallax.init(
        view => {
        //   console.log( view.x, view.y, view.z );
        camera.position.set(view.x * parallaxAmp, view.y * parallaxAmp, 6)

        // updateCameraPos({
        //   x: view.x,
        //   y: view.y,
        //   z: view.z
        // })
      }, {
        smoothEye: 0.8, // smoothing eye (x, y)
        smoothDist: 0.25, // smoothing distance (z)
        defautDist: 0.12, // parameter for distance estimation
        threshold: 0.5 // 0.85 // blazeface detection probability
      }).then( rafId => {
        console.log( 'cancelAnimationFrame(' + rafId + ')' );
      }).catch( errorMessage => {
        console.log( errorMessage );
      } );
    }

    // On Mount
    useEffect(() => initParallax(), [])

    return <>

        {/* <Perf position="top-left" /> */}

        <OrbitControls makeDefault />

        <directionalLight castShadow position={ [ 1, 2, 3 ] } intensity={ 1.5 } shadow-normalBias={ 0.04 } />
        <ambientLight intensity={ 0.5 } />

        <mesh receiveShadow position-y={ - 1 } rotation-x={ - Math.PI * 0.5 } scale={ 30 }>
            <planeGeometry />
            <meshStandardMaterial color="greenyellow" />
        </mesh>

        <Suspense fallback={ <Placeholder position-y={ 0.5 } scale={ [ 2, 3, 2 ] } /> }>
            {/* <Hamburger scale={ 0.35 } /> */}
        </Suspense>

        <Goal />

    </>
}
