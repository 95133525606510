import { useAnimations, useGLTF } from '@react-three/drei'
// import { useControls } from 'leva'
// import { useEffect } from 'react'

export default function Goal()
{
    const goal = useGLTF('./gideon-goal.glb')
    // const animations = useAnimations(goal.animations, goal.scene)

    // const { animationName } = useControls({
    //     animationName: { options: animations.names }
    // })

    // useEffect(() =>
    // {
    //     // const action = animations.actions[animationName]
    //     // action
    //     //     .reset()
    //     //     .fadeIn(0.5)
    //     //     .play()

    //     return () =>
    //     {
    //         action.fadeOut(0.5)
    //     }
    // }, [ animationName ])

    return <primitive
        object={ goal.scene }
        scale={ 0.14}
        position={ [ 0, -0.1, 0 ] }
        rotation-y={ 0.3 }
    />
}
